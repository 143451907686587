import React from "react"

import useSearchParams from "components/atoms/useSearchParams"
import ConfirmContainer from "components/forms/ConfirmContainer"
import SEO from "components/seo"

const ConfirmPage: React.FC = () => {
  const params = useSearchParams((params) => ({
    request: params.get("request"),
  }))

  return (
    <>
      <SEO title="受け付けました" noindex />
      <ConfirmContainer request={params.request} />
    </>
  )
}

export default ConfirmPage
