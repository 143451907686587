import { useState } from "react"

function useSearchParams<T>(parser: (params: URLSearchParams) => T): T {
  const [params] = useState(() => {
    const params = new URLSearchParams(
      typeof window === "undefined" ? undefined : window.location.search
    )
    return parser(params)
  })

  return params
}

export default useSearchParams
