import React from "react"

import Footer from "components/layout/Footer"
import Header from "components/layout/Header"

const ConfirmContainer: React.FC<{ request: string | null }> = ({
  request,
}) => {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="flex-grow bg-background-color">
        <div className="max-w-5xl px-4 mx-auto mt-8 text-xl font-bold text-secondary">
          <p>お申し込みいただきありがとうございます。</p>
          <p>{messageFor(request)}</p>
        </div>
      </main>
      <Footer />
    </div>
  )
}

const messageFor = (request: string | null) => {
  switch (request) {
    case "document":
      return "記載いただいたアドレス宛にダウンロードリンクをお送りしましたので、ご確認ください。"
    case "trial":
    default:
      return "記載いただいたアドレス宛にメールをお送りしましたので、ご確認ください。"
  }
}

export default ConfirmContainer
